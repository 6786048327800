// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from 'common/components/Button';

// Styling
import { prizeTile,
  prizeTileTwoUp,
  main,
  sub,
  winnerHeading } from './styles.module.css';

const PrizeTile = ({
  subHeading,
  mainHeading,
  backgroundUrl,
  twoup,
  buttonText,
  buttonTo,
  winnerText,
}) => (
  <div
    className={`${prizeTile} ${twoup && prizeTileTwoUp}`}
    style={backgroundUrl && { backgroundImage: `url( ${backgroundUrl} )` }}
  >
    {mainHeading && (
      <h2 className={`${main} ${twoup ? 'h2' : 'h1'} headingFont`}>
        {mainHeading}
      </h2>
    )}

    {subHeading && <p className={`${sub} h2`}>{subHeading}</p>}

    {buttonText && buttonTo && (
      <>
        <div style={{ flexGrow: 1 }} />
        <Button
          to={buttonTo}
          text={buttonText}
          modifiers="secondary"
          extraClasses="u-uppercase"
        />
      </>
    )}

    {winnerText && (
      <>
        <div style={{ flexGrow: 1 }} />
        <h4 className={winnerHeading}>{winnerText}</h4>
      </>
    )}
  </div>
);

PrizeTile.propTypes = {
  mainHeading: PropTypes.string.isRequired,
  backgroundUrl: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonTo: PropTypes.string.isRequired,
  winnerText: PropTypes.string,
  subHeading: PropTypes.string,
  twoup: PropTypes.bool,
};

PrizeTile.defaultProps = {
  winnerText: '',
  subHeading: '',
  twoup: false,
};

export default PrizeTile;
