// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Data
import data from 'data/data.json';

// Components
import Banner from 'marketing/components/Banner';

const Notification = ({ message, linkText, toggleOrderModalState, disableGlobalBanner }) => {
  const {
    notificationBanner: {
      displayBanner,
      bannerMessage,
      orderTicketLinkText,
    } = {},
  } = data.raffle;

  if (message || linkText) {
    return (
      <Banner
        message={message}
        linkText={linkText}
        toggleOrderModalState={toggleOrderModalState}
      />
    );
  }

  if (displayBanner && !disableGlobalBanner) {
    return (
      <Banner
        message={bannerMessage}
        linkText={orderTicketLinkText}
        toggleOrderModalState={toggleOrderModalState}
      />
    );
  }

  return false;
};

Notification.propTypes = {
  global: PropTypes.bool,
  message: PropTypes.string,
  linkText: PropTypes.string,
};

export default Notification;
