// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Button from 'common/components/Button';

// Redux
import { toggleOrderModal } from 'ducks/modals/actions';

const BuyTicketsButtonContainer = ({ toggleOrderModalState }) => (
  <Button
    onClick={toggleOrderModalState}
    text="Order Tickets"
    modifiers="secondary large icon order"
    extraClasses="u-uppercase js-order-modal-link"
    icon="local_activity"
  />
);

const mapDispatchToProps = {
  toggleOrderModalState: () => toggleOrderModal(),
};

export default connect(
  undefined,
  mapDispatchToProps,
)(BuyTicketsButtonContainer);
