// Libraries
import React from 'react';
import BigCalendar from 'react-big-calendar';
import PropTypes from 'prop-types';
import moment from 'moment';

// Components
import ToolBar from 'marketing/components/ToolBar';

const getCustomToolbar = toolbar => <ToolBar toolbar={toolbar} />;

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
BigCalendar.momentLocalizer(moment); // or globalizeLocalizer

// TODO: What is `e`? Also, destructure
const isToday = e => moment().diff(e.event.start) > 0;

const components = {
  toolbar: getCustomToolbar,
  // TODO: What is `e`?
  event: e => (
    <div>
      {e.event.prize && (
        <div
          className={`calendar__winning-prize ${isToday(e)
            && 'theme-color-primary'} h6`}
        >
          $
          {e.event.prize.toLocaleString()}
        </div>
      )}
      {e.event.name && (
        <div className="calendar__winning-name">{e.event.name}</div>
      )}
      {e.event.ticketNumber && (
        <div className="calendar__winning-ticket">
          {`Ticket # ${
            e.event.ticketNumber
          }`}
        </div>
      )}
    </div>
  ),
};

const ReactBigCalendar = ({ winnerEvents }) => (
  <div className="calendar__container">
    <BigCalendar components={components} events={winnerEvents} views={['month']} />
  </div>
);

export default ReactBigCalendar;

ReactBigCalendar.propTypes = {
  winnerEvents: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
};
