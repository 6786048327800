// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const ToolBar = ({ toolbar }) => {
  const { date } = toolbar;

  const goToBack = () => {
    date.setMonth(date.getMonth() - 1);
    toolbar.onNavigate('prev');
  };

  const goToNext = () => {
    date.setMonth(date.getMonth() + 1);
    toolbar.onNavigate('next');
  };

  return (
    <div className="calendar__nav">
      <button className="calendar__prev-next" onClick={goToBack} type="button">
        &#8249;
      </button>
      <div className="calendar__month h3">
        <span>{moment(date).format('MMMM YYYY')}</span>
      </div>
      <button className="calendar__prev-next" onClick={goToNext} type="button">
        &#8250;
      </button>
    </div>
  );
};

ToolBar.propTypes = {
  toolbar: PropTypes.shape({
    date: PropTypes.instanceOf(Date),
    label: PropTypes.string,
    messages: PropTypes.object,
    onNavigate: PropTypes.func,
    onViewChange: PropTypes.func,
    view: PropTypes.string,
    views: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default ToolBar;
