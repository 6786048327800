// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Head from 'marketing/components/Head';
import Notification from 'marketing/components/Notification';
import CtaBlock from 'marketing/components/CtaBlock';
import OrderModal from 'marketing/components/OrderModal';
import Navigation from 'marketing/components/Navigation';
import SupportersSection from 'marketing/components/SupportersSection';
import Footer from 'marketing/components/Footer';

// Data
import data from 'data/data.json';

// Redux
import { toggleContactModal,
  toggleOrderModal,
  setContactModalContent } from 'ducks/modals/actions';
import { toggleDropdownList } from 'ducks/menu/actions';

const LayoutContainer = ({
  children,
  homeHeroNav,
  includeOrderTicketsCta,
  includeWinnersLink,
  toggleContactModalState,
  displayContactModal,
  contactModalContent,
  setContactModalContentState,
  toggleOrderModalState,
  displayOrderModal,
  disableGlobalBanner
}) => {
  const {
    orderTicketsPage: {
      tollFreePhone,
      localPhone,
      mailDetails,
      mailPdf,
      inPersonDetails,
    } = {},
    globalModule: {
      ticketPriceText,
      orderTicketsHeading,
      subheading,
      backgroundImage,
    } = {},
  } = data.raffle;

  return (
    <>
      <Head />

      {homeHeroNav || (
        <>
          <Navigation />
          <Notification
            disableGlobalBanner={disableGlobalBanner}
            toggleOrderModalState={toggleOrderModalState}
          />
        </>
    
      )}
      {children}

      {includeOrderTicketsCta && (
        <CtaBlock
          mainHeading={orderTicketsHeading}
          subHeading={subheading}
          pricing={ticketPriceText}
          backgroundImage={backgroundImage}
        />
      )}

      <div className="u-relative u-bg-canvas u-z-index-1">
        <SupportersSection />
        <Footer
          includeWinnersLink={includeWinnersLink}
          toggleContactModalState={toggleContactModalState}
          displayContactModal={displayContactModal}
        />
      </div>

      <OrderModal
        ticketPriceText={ticketPriceText}
        tollFreePhone={tollFreePhone}
        localPhone={localPhone}
        mailDetails={mailDetails}
        mailPdf={mailPdf}
        inPersonDetails={inPersonDetails}
        displayOrderModal={displayOrderModal}
        toggleOrderModalState={toggleOrderModalState}
        contactModalContent={contactModalContent}
        setContactModalContentState={setContactModalContentState}
      />
    </>
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.node,
  homeHeroNav: PropTypes.node,
  includeOrderTicketsCta: PropTypes.bool,
  includeWinnersLink: PropTypes.bool,
};

const mapStateToProps = ({ modalState }) => ({
  displayContactModal: modalState.displayContactModal,
  displayOrderModal: modalState.displayOrderModal,
  contactModalContent: modalState.contactModalContent,
});

const mapDispatchToProps = {
  toggleContactModalState: () => toggleContactModal(),
  toggleOrderModalState: () => toggleOrderModal(),
  setContactModalContentState: content => setContactModalContent(content),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutContainer);
