import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const processModifiers = (modifiers, extraClasses, className) => {
  const modifierArray = modifiers.split(' ');
  const classes = modifierArray.map(modifier => `button--${modifier}`);
  let theme = '';
  if (modifierArray[0] === 'primary') {
    theme = 'theme-background-primary theme-color-secondary';
  } else if (modifierArray[0] === 'secondary') {
    theme = 'theme-background-secondary';
  }
  return cx('button', ...classes, extraClasses, className, theme);
};

const Button = (
  { icon,
    to,
    text,
    modifiers,
    extraClasses,
    className,
    onClick,
    disabled,
    external },
) => {
  const renderIcon = icon && (
    <i className="material-icons">
      {icon}
    </i>
  );
  const buttonClass = processModifiers(modifiers, extraClasses, className);

  if (to) {
    return (
      <a
        href={to}
        className={buttonClass}
        target={external && '_blank'}
      >
        {renderIcon}
        {text}
      </a>
    );
  }
  return (
    <button type="button" className={buttonClass} onClick={onClick} disabled={disabled}>
      {renderIcon}
      {text}
    </button>
  );
};

Button.propTypes = {
  icon: PropTypes.string,
  to: PropTypes.string,
  text: PropTypes.string,
  modifiers: PropTypes.string,
  extraClasses: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
};

export default Button;
