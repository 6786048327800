// Libraries
import React from 'react';

// Images
import iconFacebook from 'images/icon-facebook.png';
import iconTwitter from 'images/icon-twitter.png';
import iconInstagram from 'images/icon-instagram.png';
import iconYoutube from 'images/icon-youtube.png';

// Data
import data from 'data/data.json';

// Styling
import { socialNav,
  socialNavHeading,
  socialNavItem,
  socialNavlink } from './styles.module.css';

const SocialNav = () => {
  const {
    globalModule: { facebookUrl, twitterUrl, youtubeUrl, instagramUrl } = {},
  } = data.raffle;

  const socialItem = (type, iconSrc, url) => (
    <li className={socialNavItem}>
      <a href={url} className={socialNavlink}>
        <span className="u-screen-reader">{type}</span>
        <img src={iconSrc} alt={`${type} Icon`} />
        {' '}
      </a>
    </li>
  );

  return (
    <>
      {(facebookUrl || twitterUrl || youtubeUrl || instagramUrl) && (
        <p className={`${socialNavHeading} theme-color-primary`}>
          Join the Conversation
        </p>
      )}
      <ul className={socialNav}>
        {facebookUrl && socialItem('facebook', iconFacebook, facebookUrl)}

        {twitterUrl && socialItem('twitter', iconTwitter, twitterUrl)}

        {youtubeUrl && socialItem('youTube', iconYoutube, youtubeUrl)}

        {instagramUrl && socialItem('instagram', iconInstagram, instagramUrl)}
      </ul>
    </>
  );
};

export default SocialNav;
