// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Head from 'marketing/components/Head';
import Footer from 'marketing/components/Footer';

// Styles
import { layoutContainer } from './styles.module.css';

const Maintenance = ({ heading, subheading, messageText, logoSrc }) => (
  <>
    <Head />
    <div className={`${layoutContainer} l-padding theme-background-primary u-center-canvas`}>

      {logoSrc
      && <img className="l-margin-bottom" src={logoSrc} alt="" />}

      <h2 className="cta-block__heading--main h1 alt">{heading}</h2>
      <p className="cta-block__heading--sub h3">{subheading}</p>
      <div className="u-divider l-margin theme-background-tertiary" />
      <div dangerouslySetInnerHTML={{ __html: messageText }} />

    </div>
    <Footer maintenance />
  </>
);

Maintenance.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  messageText: PropTypes.string,
  logoSrc: PropTypes.string,
};

export default Maintenance;
