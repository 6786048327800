// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { notification,
  notificationMessage,
  notificationLink } from './styles.module.css';

const Banner = ({ message, linkText, toggleOrderModalState }) => (
  <div className={`${notification} theme-background-tertiary`}>
    {message && <p className={`${notificationMessage} h3`}>{message}</p>}
    {linkText && (
      <button
        type="button"
        onClick={toggleOrderModalState}
        className={notificationLink}
      >
        {linkText}
      </button>
    )}
  </div>
);

Banner.propTypes = {
  message: PropTypes.string.isRequired,
  toggleOrderModalState: PropTypes.func,
  linkText: PropTypes.string,
};

Banner.defaultProps = {
  linkText: '',
  toggleOrderModalState: () => {},
};

export default Banner;
