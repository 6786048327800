// Libraries
import React, { PureComponent } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

// Components
import onClickOutside from 'react-onclickoutside';

class Dropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      listOpen: false,
    };
  }

  toggleList = (event) => {
    event.preventDefault();
    this.setState(currentState => ({
      listOpen: !currentState.listOpen,
    }));
  };

  handleClickOutside = () => {
    this.setState({
      listOpen: false,
    });
  };

  render() {
    const { list, title } = this.props;
    const { listOpen } = this.state;

    return (
      <li className="main-nav__list-item main-nav__list-item--dropdown">
        <button
          onClick={this.toggleList}
          type="button"
          aria-haspopup="true"
          aria-expanded={listOpen}
          className={`main-nav__link dropdown__toggle ${listOpen
            && 'dropdown__toggle--open'}`}
        >
          <span className="main-nav__link-marker theme-background-secondary" />
          {title}
          <i className="material-icons">arrow_right</i>
        </button>

        <div
          className={`dropdown theme-background-primary ${listOpen
            && 'is-open'}`}
        >
          {/* Move map out of jsx */}
          {list.map(({ pageName }) => (
            <Link
              // move into utils vvv
              to={pageName.toLowerCase().replace(/\s/g, '-') || '#'}
              key={pageName}
              className="main-nav__link"
            >
              <span className="main-nav__link-marker theme-background-secondary" />
              {pageName}
            </Link>
          ))}
        </div>
      </li>
    );
  }
}

Dropdown.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      bannerMessage: PropTypes.string,
      cashPrizeHeading: PropTypes.string,
      cashPrizeSubheading: PropTypes.string,
      displayBanner: PropTypes.bool,
      displayHeroOnHomepage: PropTypes.bool,
      heroHeading: PropTypes.string,
      heroImage: PropTypes.string,
      heroSubheading: PropTypes.string,
      pageName: PropTypes.string,
      primaryPrizeComponents: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          heading: PropTypes.string,
          image: PropTypes.string,
          imageAltText: PropTypes.string,
        }),
      ),
      prizeType: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default onClickOutside(Dropdown);
