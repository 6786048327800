// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Component
import CountUp from 'react-countup';

// Styling
import { counter, counterDigit } from './styles.module.css';

const numberToStringWithSeparators = (number) => {
  if (number === 0) {
    return '$???,???'
      .split('')
      .map(x => `<span class="${counterDigit}">${x}</span>`)
      .join('');
  }
  return number
    .toString()
    .padStart(number.toString().length + 1, '$')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .split('')
    .map(x => `<span class="${counterDigit}">${x}</span>`)
    .join('');
};

const Counter = ({ total }) => (
  <CountUp
    className={counter}
    start={0}
    end={total || 0}
    duration={5}
    formattingFn={numberToStringWithSeparators}
  />
);

Counter.propTypes = {
  total: PropTypes.number.isRequired,
};

export default Counter;
