// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import TitleBlock from 'marketing/components/TitleBlock';
import PrizeTile from 'marketing/components/PrizeTile';

// Styles
import { prizeGrid } from './styles.module.css';

const generatePrizeTiles = (otherGrandPrizes, twoUp) => otherGrandPrizes.map(prize => (
  <PrizeTile
    buttonText="Discover more"
    buttonTo={prize.pageName.toLowerCase().replace(/\s/g, '-')}
    mainHeading={prize.heroHeading}
    subHeading={prize.heroSubheading}
    backgroundUrl={prize.heroImage}
    twoup={twoUp}
  />
));

const GrandPrizeGrid = ({
  mainHeading,
  subHeading,
  twoUp,
  otherGrandPrizes,
}) => (
  <div className={`${prizeGrid} theme-background-primary`}>
    <div className="l-container">
      <TitleBlock
        mainHeading={mainHeading}
        subHeading={subHeading}
        onThemeBackground
      />
      {generatePrizeTiles(otherGrandPrizes, twoUp)}
    </div>
  </div>
);

GrandPrizeGrid.propTypes = {
  mainHeading: PropTypes.string,
  subHeading: PropTypes.string,
  twoUp: PropTypes.bool.isRequired,
  otherGrandPrizes: PropTypes.arrayOf(
    PropTypes.shape({
      heroHeading: PropTypes.string,
      heroImage: PropTypes.string,
      pageName: PropTypes.string,
      primaryPrizeComponents: PropTypes.arrayOf(
        PropTypes.shape({
          contribution: PropTypes.string,
          description: PropTypes.string,
          heading: PropTypes.string,
          image: PropTypes.string,
          imageAltText: PropTypes.string,
          mapImage: PropTypes.string,
          primaryPrizeButtons: PropTypes.arrayOf(
            PropTypes.shape({
              buttonText: PropTypes.string,
              pdf: PropTypes.string,
            }),
          ),
          showhomeAddressText: PropTypes.string,
          showhomeBlockTitle: PropTypes.string,
          showhomeViewingHours: PropTypes.string,
          subheading: PropTypes.string,
          supplierLogo: PropTypes.string,
          supportText: PropTypes.string,
        }),
      ),
      prizeType: PropTypes.string,
      titleHeading: PropTypes.string,
    }),
  ).isRequired,
};

GrandPrizeGrid.defaultProps = {
  subHeading: '',
  mainHeading: '',
};

export default GrandPrizeGrid;
