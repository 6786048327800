// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import BuyTicketsButtonContainer from 'marketing/containers/BuyTicketsButtonContainer';
import Button from 'common/components/Button';

// Styling
import { cta, main, sub, pricingText } from './styles.module.css';

const CtaBlock = ({
  mainHeading,
  subHeading,
  pricing,
  buttonTo,
  buttonText,
  backgroundImage,
}) => (
  <div
    className={`${cta} theme-background-primary`}
    style={backgroundImage && { backgroundImage: `url(${backgroundImage})` }}
  >
    {mainHeading && <h2 className={`${main} headingFont h1`}>{mainHeading}</h2>}

    {subHeading && <h3 className={sub}>{subHeading}</h3>}

    {(buttonTo && buttonText && (
      <Button
        modifiers="secondary"
        extraClasses="u-uppercase"
        to={buttonTo}
        text={buttonText}
      />
    )) || <BuyTicketsButtonContainer />}

    {pricing && (
      <div
        className={`${pricingText} h5`}
        dangerouslySetInnerHTML={{ __html: pricing }}
      />
    )}
  </div>
);

CtaBlock.propTypes = {
  backgroundImage: PropTypes.string,
  subHeading: PropTypes.string,
  buttonTo: PropTypes.string,
  buttonText: PropTypes.string,
  pricing: PropTypes.string,
  mainHeading: PropTypes.string,
};

CtaBlock.defaultProps = {
  // TODO: figure out why this has to be undefined by default
  backgroundImage: undefined,
  subHeading: '',
  buttonTo: '',
  buttonText: '',
  pricing: '',
  mainHeading: '',
};

export default CtaBlock;
