// Libraries
import React, { Fragment } from 'react';

// Components
import LayoutContainer from 'marketing/containers/LayoutContainer';
import GrandPrizeSwiper from 'marketing/components/GrandPrizeSwiper';
import GrandPrizeGrid from 'marketing/components/GrandPrizeGrid';
import CategoryPrizeSwiper from 'marketing/components/CategoryPrizeSwiper';
import CtaBlock from 'marketing/components/CtaBlock';
import PrizeTile from 'marketing/components/PrizeTile';
import TitleBlock from 'marketing/components/TitleBlock';
import AddOn5050 from 'marketing/components/AddOn5050';
import AddOn100Days from 'marketing/components/AddOn100Days';
import Notification from 'marketing/components/Notification';
import Navigation from 'marketing/components/Navigation';

// Utilities
import { getFeaturedCategoryPrizes } from 'utils/helpers';

// Data
import data from 'data/data.json';

// Templates (for winners/maintenance mode)
import Maintenance from '../templates/maintenance';
import Winners from '../templates/winners';

// NOTE this getCurrentPrizeWinners Function is also used in gatsby-node.js
const getCurrentPrizeWinners = (_primaryPrizePages, _addOnPages, _winnersPage) => {
  const primaryPrizeWithWinners = _primaryPrizePages
    .filter(primaryPrize => primaryPrize.winner);

  const grandWinners = primaryPrizeWithWinners
    .filter(primaryPrize => primaryPrize.prizeType === 'grand_prize');

  const otherWinners = primaryPrizeWithWinners
    .filter(primaryPrize => primaryPrize.prizeType !== 'grand_prize');

  const addOnWinners = _addOnPages
    .filter(addOn => addOn.winner && addOn.addOnType === 'fifty_fifty');

  // if we have any primiary prize winners return those winners.
  if (grandWinners.length || otherWinners.length || addOnWinners.length) {
    const winners = {
      grandWinners,
      addOnWinners,
      otherWinners,
    };
    return winners;
  }

  // if we have any cash calendar winners we still want to generate the /winners page and footer link so return an empty object.
  if (_winnersPage.calendarWinners && _winnersPage.calendarWinners.length) {
    return {};
  }

  // If we have no primary prize winners OR any cash calendar winners we will not generate a /winners page, as we have no winners to display.
  return false;
};

const indexPageComponment = () => {
  const {
    maintenance,
    primaryPrizePages,
    notificationBanner,
    homePage: {
      homePageSlides,
      grandPrizeCtaHeading,
      grandPrizeCtaSubheading,
      grandPrizeCtaButtonText,
      grandPrizeHeading,
      grandPrizeSubheading,
      pageLayout,
      carouselHeading,
      carouselSubheading,
    } = {},
    globalModule: {
      orderTicketsHeading,
      subheading,
      ticketPriceText,
      backgroundImage,
    } = {},
    categoryPrizePages,
    addOnPages,
    appearance: {
      programLogo,
    } = {},
    winnersPage,
  } = data.raffle;

  // If maintenace mode is enabled then only render the Maintenance template.
  if (maintenance && maintenance.maintenanceMode) {
    const {
      heading,
      messageText,
    } = maintenance;

    return (
      <Maintenance
        heading={heading}
        subheading={maintenance.subheading}
        messageText={messageText}
        logoSrc={programLogo}
      />
    );
  }

  const currentPrizeWinners = getCurrentPrizeWinners(primaryPrizePages, addOnPages, winnersPage);

  // If Winners mode is enabled then only render the Winners template.
  if (winnersPage.winnersMode) {
    return (
      <Winners winnersPageData={winnersPage} currentPrizeWinners={currentPrizeWinners} />
    );
  }

  const displayGlobalBanner = notificationBanner && notificationBanner.displayBanner;

  // Featured Primary Prizes are primary prizes that have the displayHeroOnHomepage flag and are NOT Grand Prizes (e.g Early Bird & Bonus prize(s))
  const featuredPrimaryPrizes = primaryPrizePages
    .filter(primaryPrize => primaryPrize.prizeType !== 'grand_prize')
    .filter(primaryPrize => primaryPrize.displayHeroOnHomepage)
    .sort((a, b) => {
      if (a.displayOrderIndex && b.displayOrderIndex) {
        return a.displayOrderIndex - b.displayOrderIndex;
      }
      if (!a.displayOrderIndex) {
        return 1;
      }
      return -1;
    });

  // Other Grand Prizes are all grand prizes except for the first (which is the Grandest of Grand prizes)
  const otherGrandPrizes = primaryPrizePages
    .filter(prize => prize.prizeType === 'grand_prize')
    .slice(1);

  // Featured Catgeory Prizes are up to the first three prizes from each category prize page.
  const featuredCategoryPrizes = getFeaturedCategoryPrizes(categoryPrizePages);

  // Featured Add On Prizes (cash calendar or 50/50)
  const featuredAddOns = addOnPages
    .filter(addOnPage => addOnPage.included);

  const homePageNavigation = (
    <Navigation
      hasNotification={displayGlobalBanner}
      isHomePage
    >
      <Notification global />
      <GrandPrizeSwiper
        slides={homePageSlides}
        programLogo={programLogo}
      />
    </Navigation>
  );

  const renderAddOn = (addOn, i) => {
    switch (addOn.addOnType) {
      case 'fifty_fifty':
        return (
          <AddOn5050
            mainHeading={addOn.titleSubheading}
            pageBannerText={addOn.pageBannerText}
            imageSrc={addOn.pageLogo}
            pageBackgroundImage={addOn.pageBackgroundImage}
            total={addOn.currentPrizeTotal}
            key={i}
          />
        );
      case 'cash_calendar':
        return (
          <AddOn100Days
            mainHeading={addOn.titleSubheading}
            pageBannerText={addOn.pageBannerText}
            imageSrc={addOn.pageLogo}
            pageBackgroundImage={addOn.pageBackgroundImage}
            key={i}
          />
        );
      default:
        return false;
    }
  };

  return (
    <LayoutContainer
      homeHeroNav={homePageNavigation}
      includeWinnersLink={!!currentPrizeWinners}
    >

      <div className="u-relative u-bg-canvas u-z-index-10">

        <CtaBlock
          mainHeading={grandPrizeCtaHeading}
          subHeading={grandPrizeCtaSubheading}
          buttonText={grandPrizeCtaButtonText}
          buttonTo="/grand-prize"
        />

        <div className="l-container l-margin-top">

          {featuredPrimaryPrizes.map(featuredPrimaryPrize => (
            <Fragment key={featuredPrimaryPrize.pageName}>

              {featuredPrimaryPrize.displayBanner
              && (
              <Notification
                message={featuredPrimaryPrize.bannerMessage}
                linkText={featuredPrimaryPrize.bannerTicketsLinkText}
              />
              )}

              <PrizeTile
                mainHeading={featuredPrimaryPrize.heroHeading}
                subHeading={featuredPrimaryPrize.heroSubheading}
                buttonText="More Info"
                buttonTo={featuredPrimaryPrize.pageName.toLowerCase().replace(/\s/g, '-')}
                backgroundUrl={featuredPrimaryPrize.heroImage}
              />
            </Fragment>
          ))}

        </div>

        {otherGrandPrizes.length > 0
        && (
        <GrandPrizeGrid
          twoUp={pageLayout === 'grid'}
          mainHeading={grandPrizeHeading}
          subHeading={grandPrizeSubheading}
          otherGrandPrizes={otherGrandPrizes}
        />
        )}

        {featuredCategoryPrizes.length > 0
        && (
        <>
          <TitleBlock
            mainHeading={carouselHeading}
            subHeading={carouselSubheading}
          />
          <CategoryPrizeSwiper
            prizes={featuredCategoryPrizes}
          />
        </>
        )}

        {featuredAddOns.length > 0
        && (
        <div className="l-container">
          {featuredAddOns.map(renderAddOn)}
        </div>
        )}

        <CtaBlock
          mainHeading={orderTicketsHeading}
          subHeading={subheading}
          pricing={ticketPriceText}
          backgroundImage={backgroundImage}
        />

      </div>

    </LayoutContainer>
  );
};

export default indexPageComponment;
