// Libraries
import React from 'react';

// Data
import data from 'data/data.json';

// Contants
// Google Fonts
const FONT_URL_BASE = 'https://fonts.googleapis.com/css?family=Material+Icons|Open+Sans:400,600|';

const {
  appearance: {
    headingTypeface,
    primaryColour,
    buttonColour,
    notificationBannerColour,
  } = {},
} = data.raffle;

// TODO: find a better way to include, or at least rename component
const Head = () => (
  <>
    {/* <!-- TODO look into self hosting smaller icon font set or create svg sprite --> */}
    <link
      href={`${FONT_URL_BASE}${headingTypeface
        && headingTypeface.replace(/\s/g, '+')}:700`}
      rel="stylesheet"
    />

    <style
      dangerouslySetInnerHTML={{
        __html: `.theme-color-primary, .wysiwyg h5 {
          color: ${primaryColour};
        }
        .theme-color-secondary {
          color: ${buttonColour};
        }
        .theme-color-tertiary {
          color: ${notificationBannerColour};
        }
        .theme-background-primary {
          background-color: ${primaryColour};
        }
        .theme-background-secondary {
          background-color: ${buttonColour};
        }
        .theme-background-tertiary {
          background-color: ${notificationBannerColour};
        }
        .lds-spinner div:after {
          background-color: ${primaryColour};
        }
        .alt {
          font-family: ${headingTypeface};
        }
        .headingFont {
          font-family: ${headingTypeface};
        }`,
      }}
    />
  </>
);

export default Head;
