// Libraries
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

// Components
import BuyTicketsButtonContainer from 'marketing/containers/BuyTicketsButtonContainer';

const NavBar = ({
  displayMobileMenu,
  toggleMenu,
  programLogo,
  grandPrizeLinks,
  earlyBirdLinks,
  morePrizeLinks,
  addOnLinks,
  proceedsLink,
}) => (
  <div
    className={`header theme-background-primary ${displayMobileMenu
      && ' is-open'}`}
  >
    <div className="l-container header__mobile-container">
      <div className="l-col-12 header__mobile">
        <button
          onClick={toggleMenu}
          type="button"
          tabIndex="0"
          className="trigger"
        >
          <span />
          <span />
          <span />
          <p className="trigger__label trigger__label--menu">Menu</p>
          <p className="trigger__label trigger__label--close">Close</p>
        </button>
        <BuyTicketsButtonContainer />
      </div>
    </div>

    <div className="l-container">
      <div className="header__wrapper">
        <Link to="/">
          <img
            className="header__logo-link"
            src={programLogo}
            alt=" "
          />
        </Link>

        <nav className="main-nav">
          <ul className="main-nav__list theme-background-primary">
            {grandPrizeLinks}
            {earlyBirdLinks}
            {morePrizeLinks}
            {addOnLinks}
            {proceedsLink}
            <li className="main-nav__list-item main-nav__list-item--button">
              <BuyTicketsButtonContainer />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
);

NavBar.propTypes = {
  displayMobileMenu: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  programLogo: PropTypes.string,
  grandPrizeLinks: PropTypes.element.isRequired,
  earlyBirdLinks: PropTypes.arrayOf(
    PropTypes.element,
  ).isRequired,
  morePrizeLinks: PropTypes.element.isRequired,
  addOnLinks: PropTypes.element.isRequired,
  proceedsLink: PropTypes.element.isRequired,
};

NavBar.defaultProps = {
  programLogo: '',
};

export default NavBar;
