import React from 'react';
import ReactModal from 'react-modal';
import Button from 'common/components/Button';
import PropTypes from 'prop-types';

// Constants
// Model content options
const IN_PERSON = 'IN_PERSON';
const BY_MAIL = 'BY_MAIL';
const DEFAULT = 'DEFAULT';

//  This is needed so screen readers don’t see main content when modal is opened.
ReactModal.setAppElement('body');

const OrderModal = ({
  tollFreePhone,
  localPhone,
  mailDetails,
  mailPdf,
  inPersonDetails,
  ticketPriceText,
  contactModalContent,
  displayOrderModal,
  toggleOrderModalState,
  setContactModalContentState,
}) => {
  const renderModalDisplay = (content) => {
    // TODO: Refactor each case into seperate components
    switch (content) {
      case IN_PERSON:
        return (
          <>
            <Button
              onClick={() => setContactModalContentState(DEFAULT)}
              modifiers="plain"
              extraClasses="modal__close"
              icon="close"
            />

            <div className="modal__content">
              <h2 className="alt modal__title theme-color-primary">
                Order Tickets In Person
              </h2>
              <div
                className="modal__copy"
                dangerouslySetInnerHTML={{ __html: inPersonDetails }}
              />

              <Button
                onClick={() => setContactModalContentState(DEFAULT)}
                text="close"
                modifiers="plain"
                extraClasses="modal__close--text theme-color-primary"
              />
            </div>
          </>
        );
      case BY_MAIL:
        return (
          <>
            <Button
              onClick={() => setContactModalContentState(DEFAULT)}
              modifiers="plain"
              extraClasses="modal__close"
              icon="close"
            />

            <div className="modal__content">
              <h2 className="alt modal__title theme-color-primary">
                Order Tickets By Mail
              </h2>

              {mailDetails && (
                <div
                  className="modal__copy l-padding-bottom"
                  dangerouslySetInnerHTML={{ __html: mailDetails }}
                />
              )}

              {mailPdf && (
                <Button
                  to={mailPdf}
                  text="Download Ticket Order Form"
                  modifiers="secondary"
                />
              )}

              <Button
                onClick={() => setContactModalContentState(DEFAULT)}
                text="close"
                modifiers="plain"
                extraClasses="modal__close--text theme-color-primary"
              />
            </div>
          </>
        );
      default:
        return (
          <>
            <Button
              onClick={toggleOrderModalState}
              modifiers="plain"
              extraClasses="modal__close"
              icon="close"
            />

            <div className="modal__content">
              <h2 className="alt modal__title">Order Your Tickets</h2>

              <div className="button__set">
                <Button
                  to="/buy-flow"
                  text="Order Online"
                  modifiers="secondary jumbo icon"
                  extraClasses=""
                  icon="laptop"
                />
              </div>

              <div className="button__set">
                {tollFreePhone && (
                  <Button
                    to={`tel:${tollFreePhone.replace(/\D/g, '')}`}
                    text={tollFreePhone}
                    modifiers="secondary small icon"
                    extraClasses=""
                    icon="phone_iphone"
                  />
                )}

                {localPhone && (
                  <Button
                    to={`tel:${localPhone.replace(/\D/g, '')}`}
                    text={localPhone}
                    modifiers="secondary small icon"
                    extraClasses=""
                    icon="phone_iphone"
                  />
                )}

                {mailDetails && (
                  <Button
                    onClick={() => setContactModalContentState(BY_MAIL)}
                    text="By Mail"
                    modifiers="secondary small icon"
                    extraClasses=""
                    icon="email"
                  />
                )}

                {inPersonDetails && (
                  <Button
                    onClick={() => setContactModalContentState(IN_PERSON)}
                    text="In Person"
                    modifiers="secondary small icon"
                    extraClasses=""
                    icon="directions_walk"
                  />
                )}
              </div>

              {ticketPriceText && (
                <div className="modal__copy">
                  <div
                    className="p"
                    dangerouslySetInnerHTML={{ __html: ticketPriceText }}
                  />
                </div>
              )}
            </div>
          </>
        );
    }
  };

  return (
    <ReactModal
      isOpen={displayOrderModal}
      contentLabel="Order Tickets Modal"
      className={`modal ${
        contactModalContent === DEFAULT
          ? 'theme-background-primary u-center-canvas'
          : 'u-text-align-center'
      }`}
      overlayClassName="modal__overlay"
    >
      {renderModalDisplay(contactModalContent)}
    </ReactModal>
  );
};

OrderModal.propTypes = {
  tollFreePhone: PropTypes.string,
  localPhone: PropTypes.string,
  mailDetails: PropTypes.string,
  mailPdf: PropTypes.string,
  inPersonDetails: PropTypes.string,
  ticketPriceText: PropTypes.string,
  contactModalContent: PropTypes.string,
  displayOrderModal: PropTypes.bool.isRequired,
  toggleOrderModalState: PropTypes.func.isRequired,
  setContactModalContentState: PropTypes.func.isRequired,
};

OrderModal.defaultProps = {
  tollFreePhone: '',
  localPhone: '',
  mailDetails: '',
  mailPdf: '',
  inPersonDetails: '',
  ticketPriceText: '',
  contactModalContent: '',
};

export default OrderModal;
