// Libraries
import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

// Components
import Button from 'common/components/Button';

const ContactModal = ({
  details,
  displayContactModal,
  toggleContactModalState,
}) => (
  <>
    <button
      type="button"
      className="footer-nav__link button button--plain theme-color-primary"
      onClick={toggleContactModalState}
    >
      Contact Us
    </button>

    <ReactModal
      isOpen={displayContactModal}
      contentLabel="Contact Modal"
      className="modal u-text-align-center"
      overlayClassName="modal__overlay"
    >
      <Button
        onClick={toggleContactModalState}
        modifiers="plain"
        extraClasses="modal__close"
        icon="close"
      />
      <div className="modal__content">
        <h2 className="alt modal__title theme-color-primary">Contact Us</h2>
        <div
          className="modal__copy"
          dangerouslySetInnerHTML={{ __html: details }}
        />
      </div>
    </ReactModal>
  </>
);

ContactModal.propTypes = {
  details: PropTypes.string,
  displayContactModal: PropTypes.bool.isRequired,
  toggleContactModalState: PropTypes.func.isRequired,
};

ContactModal.defaultProps = {
  details: '',
};

export default ContactModal;
