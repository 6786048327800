// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LayoutContainer from 'marketing/containers/LayoutContainer';
import TitleBlock from 'marketing/components/TitleBlock';
import PrizeTile from 'marketing/components/PrizeTile';
import AddOn5050 from 'marketing/components/AddOn5050';
import Button from 'common/components/Button';
import ReactBigCalendar from 'marketing/components/ReactBigCalendar';

// Styles
import { layoutContainer } from './styles.module.css';

const Winners = ({ winnersPageData, pageContext, currentPrizeWinners }) => {
  const pageDataSource = pageContext ? pageContext.data : winnersPageData;
  const winnersDataSource = pageContext ? pageContext.prizeWinners : currentPrizeWinners;

  const {
    cashCalendarAnnounce,
    heading,
    subheading,
    introduction,
    legalText,
    homeLotteryButtonText,
    homeLotteryWinnersPdf,
    thankYouMessage,
    supplierLogos,
    secondarySuppliersList,
    calendarWinners,
  } = pageDataSource;

  const {
    grandWinners,
    otherWinners,
    addOnWinners,
  } = winnersDataSource;

  // TODO remove announceTime if we are not useing it. winners to come from Elmar's API?
  const announceTime = new Date(cashCalendarAnnounce);

  const today = new Date();
  today.setHours(announceTime.getHours());
  today.setMinutes(announceTime.getMinutes());
  today.setSeconds(announceTime.getSeconds());

  const structuredCalendarWinners = calendarWinners && calendarWinners.map((item, index) => {
    const itemDate = new Date(`${item.date}T00:00:00`);

    if (itemDate < today) {
      return ({
        id: index,
        prize: item.amount,
        name: `${item.firstName} ${item.lastName}`,
        ticketNumber: item.ticketNumber,
        start: itemDate,
        end: itemDate,
      });
    }
    return {};
  });

  return (
    <LayoutContainer
      disableGlobalBanner
    >
      {(heading || subheading || introduction)
      && (
      <div className="l-col-offset-2 l-col-8">
        <TitleBlock
          mainHeading={heading}
          subHeading={subheading}
        />
        <div className="wysiwyg l-margin-top">
          <div
            className="t-intro t-center theme-color-primary l-margin"
            dangerouslySetInnerHTML={{ __html: introduction }}
          />
        </div>
      </div>
      )}

      <Tabs selectedTabClassName="theme-background-primary react-tabs__tab--selected">

        <TabList className="react-tabs__tab-list theme-background-secondary l-margin">

          {(otherWinners || addOnWinners || grandWinners)
          && (
          <Tab>
            Home Lottery Winners
          </Tab>
          )}

          {structuredCalendarWinners
          && (
          <Tab>
            Cash Calendar Winners
          </Tab>
          )}

        </TabList>

        {(otherWinners || addOnWinners || grandWinners)
        && (
        <TabPanel>

          {(grandWinners && grandWinners.length > 0)
          && (
          <div className="theme-background-primary l-padding">
            <div className={layoutContainer}>
              {grandWinners.map(prizeWinner => (
                <PrizeTile
                  mainHeading={prizeWinner.heroHeading}
                  subHeading="Congratulations"
                  winnerText={prizeWinner.winner}
                  backgroundUrl={prizeWinner.heroImage}
                  key={prizeWinner.winner}
                />
              ))}
            </div>
          </div>
          )}

          {(addOnWinners && addOnWinners.length > 0)
          && (
          <div className={`${layoutContainer} l-padding-top`}>
            {addOnWinners.map(addOnWinner => (
              <AddOn5050
                mainHeading="And the Final Jackpot..."
                subHeading={addOnWinner.winner}
                imageSrc={addOnWinner.pageLogo}
                backgroundImageUrl={addOnWinner.pageBackgroundImage}
                winner="true"
                total={addOnWinner.currentPrizeTotal}
                key={addOnWinner.winner}
              />
            ))}
          </div>
          )}

          {(otherWinners && otherWinners.length > 0)
          && (
            <div className={layoutContainer}>
              {otherWinners.map(prizeWinner => (
                <PrizeTile
                  mainHeading={prizeWinner.heroHeading}
                  subHeading="Congratulations"
                  winnerText={prizeWinner.winner}
                  backgroundUrl={prizeWinner.heroImage}
                  key={prizeWinner.winner}
                />
              ))}
            </div>
          )}

          {(homeLotteryWinnersPdf || homeLotteryButtonText)
          && (
          <div className={`${layoutContainer} u-text-align-center l-margin-bottom`}>
            <Button
              to={homeLotteryWinnersPdf}
              text={homeLotteryButtonText}
              modifiers="secondary large"
            />
          </div>
          )}

          <div className={layoutContainer}>

            <div className="l-col-offset-2 l-col-8">
              <hr className="theme-background-primary l-margin" />
            </div>

            {thankYouMessage
            && (
            <div className="l-col-12 wysiwyg l-margin-bottom">
              <p className="theme-color-primary t-intro u-text-align-center">
                {thankYouMessage}
              </p>
            </div>
            )}

            {(supplierLogos && supplierLogos.length > 0)
            && (
            <div className="l-col-12 u-flex-center">
              {supplierLogos.map(supplierLogo => (
                <div className="l-col-4 u-text-align-center l-margin-bottom">
                  <img
                    className="u-block u-margin-center l-margin-bottom"
                    src={supplierLogo.image}
                    alt="Supplier Logo"
                  />
                </div>
              ))}
            </div>
            )}

            {secondarySuppliersList
            && (
            <div className="l-col-offset-3 l-col-6 wysiwyg u-text-align-center">
              <div
                className="theme-color-primary p l-padding-bottom"
                dangerouslySetInnerHTML={{ __html: secondarySuppliersList }}
              />
            </div>
            )}

          </div>

        </TabPanel>
        )}

        {structuredCalendarWinners
        && (
        <TabPanel>
          <div className="theme-background-primary l-padding">
            <div className={layoutContainer}>
              <ReactBigCalendar winnerEvents={structuredCalendarWinners} />
            </div>
          </div>
        </TabPanel>
        )}

      </Tabs>

      {legalText
      && (
      <div className="l-col-offset-2 l-col-8 wysiwyg l-margin l-padding-bottom">
        <hr className="theme-background-primary l-margin-bottom" />
        <div
          className="p t-small"
          dangerouslySetInnerHTML={{ __html: legalText }}
        />
      </div>
      )}

    </LayoutContainer>
  );
};

Winners.propTypes = {
  winnersPageData: PropTypes.object,
  pageContext: PropTypes.object,
  currentPrizeWinners: PropTypes.object,
};

export default Winners;
