// Make this a container, pass data/functions to Dropdown
// Libraries
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Dropdown from 'marketing/components/Dropdown';
import NavBar from 'marketing/components/NavBar';
import NavItem from 'marketing/components/NavItem';

// Redux
import { toggleMobileMenu } from 'ducks/menu/actions';

// Data
import data from 'data/data.json';

class NavBarContainer extends PureComponent {
  renderNavigation = (navBar, attribute, title) => {
    if (navBar[`${attribute}`]) {
      if (navBar[`${attribute}`].length > 1) {
        return <Dropdown title={title} list={navBar[`${attribute}`]} />;
      }
      return navBar[`${attribute}`].map((navItem, i) => (
        <NavItem
          name={navItem.pageName}
          path={navItem.pageName.toLowerCase().replace(/\s/g, '-')}
          key={i}
        />
      ));
    }
    return '';
  };

  renderProceedsPage = (navBar) => {
    if (navBar.proceedsPage) {
      return (
        <NavItem
          name={navBar.proceedsPage.pageName}
          path={navBar.proceedsPage.pageName.toLowerCase().replace(/\s/g, '-')}
        />
      );
    }
    return '';
  };

  render() {
    const { toggleMobileMenuState, displayMobileMenu } = this.props;

    const { primaryPrizePages } = data.raffle;

    // TODO: This should be broken down and dried up
    const grandPrizes = primaryPrizePages.filter(
      prize => prize.prizeType === 'grand_prize',
    );
    const earlyBirdPrizes = primaryPrizePages.filter(
      prize => prize.prizeType === 'early_bird_prize',
    );
    const morePrizesPrimary = primaryPrizePages
      .filter(
        prize => prize.prizeType !== 'grand_prize'
          && prize.prizeType !== 'early_bird_prize',
      )
      .sort((a, b) => {
        // TODO: Rename variables from a, b to something more expressive
        if (a.displayOrderIndex && b.displayOrderIndex) {
          return a.displayOrderIndex - b.displayOrderIndex;
        }
        if (!a.displayOrderIndex) {
          return 1;
        }
        return -1;
      });

    const {
      addOnPages,
      proceedsPage,
      appearance: { programLogo } = {},
      categoryPrizePages = [],
      listPrizePages = [],
    } = data.raffle;

    const morePrizes = [
      ...morePrizesPrimary,
      ...categoryPrizePages,
      ...listPrizePages,
    ];

    const navBar = {
      grandPrizes,
      morePrizes,
      earlyBirdPrizes,
      addOnPages,
      proceedsPage,
    };

    const grandPrizeLinks = this.renderNavigation(
      navBar,
      'grandPrizes',
      'Grand Prizes',
    );
    const earlyBirdLinks = this.renderNavigation(
      navBar,
      'earlyBirdPrizes',
      'Early Bird',
    );
    const morePrizeLinks = this.renderNavigation(
      navBar,
      'morePrizes',
      'More Prizes',
    );
    const addOnLinks = this.renderNavigation(
      navBar,
      'addOnPages',
      'Add-Ons',
    );
    const proceedsLink = this.renderProceedsPage(navBar);

    return (
      <NavBar
        programLogo={programLogo}
        toggleMenu={toggleMobileMenuState}
        displayMobileMenu={displayMobileMenu}
        grandPrizeLinks={grandPrizeLinks}
        earlyBirdLinks={earlyBirdLinks}
        morePrizeLinks={morePrizeLinks}
        addOnLinks={addOnLinks}
        proceedsLink={proceedsLink}
      />
    );
  }
}

NavBarContainer.propTypes = {
  toggleMobileMenuState: PropTypes.func.isRequired,
  displayMobileMenu: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ menuState }) => ({
  displayMobileMenu: menuState.displayMobileMenu,
});

const mapDispatchToProps = {
  toggleMobileMenuState: () => toggleMobileMenu(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBarContainer);
