// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import SocialNav from 'marketing/components/SocialNav';
import ContactModal from 'marketing/components/ContactModal';
import FooterText from 'common/components/FooterText';

// Data
import data from 'data/data.json';

const Footer = ({
  maintenance,
  includeWinnersLink,
  toggleContactModalState,
  displayContactModal,
}) => {
  const {
    faqPdf,
    rulesPdf,
    privacyPolicyPdf,
    importantDatesPdf,
    previousWinnersPdf,
    contactUsPage: { details } = {},
  } = data.raffle;

  return (
    <footer className="footer">
      {!maintenance && (
        <div className="footer--upper">
          <div className="l-container">
            <div className="l-col-6">
              <ul className="footer-nav">
                {faqPdf && (
                  <li className="footer-nav__item">
                    <a
                      href={faqPdf}
                      className="footer-nav__link theme-color-primary"
                    >
                      FAQ
                    </a>
                  </li>
                )}

                {rulesPdf && (
                  <li className="footer-nav__item">
                    <a
                      href={rulesPdf}
                      className="footer-nav__link theme-color-primary"
                    >
                      Rules
                    </a>
                  </li>
                )}

                {privacyPolicyPdf && (
                  <li className="footer-nav__item">
                    <a
                      href={privacyPolicyPdf}
                      className="footer-nav__link theme-color-primary"
                    >
                      Privacy Policy
                    </a>
                  </li>
                )}

                {importantDatesPdf && (
                  <li className="footer-nav__item">
                    <a
                      href={importantDatesPdf}
                      className="footer-nav__link theme-color-primary"
                    >
                      Important Dates
                    </a>
                  </li>
                )}

                {includeWinnersLink && (
                  <li className="footer-nav__item">
                    <a
                      href="/winners"
                      className="footer-nav__link theme-color-primary"
                    >
                      Winners
                    </a>
                  </li>
                )}

                {previousWinnersPdf && (
                  <li className="footer-nav__item">
                    <a
                      href={previousWinnersPdf}
                      className="footer-nav__link theme-color-primary"
                    >
                      Previous Winners
                    </a>
                  </li>
                )}

                {details && (
                  <li className="footer-nav__item">
                    <ContactModal
                      details={details}
                      toggleContactModalState={toggleContactModalState}
                      displayContactModal={displayContactModal}
                    />
                  </li>
                )}
              </ul>
            </div>

            <div className="l-col-3 l-col-offset-3">
              <SocialNav />
            </div>
          </div>
        </div>
      )}

      <FooterText />
    </footer>
  );
};

Footer.propTypes = {
  maintenance: PropTypes.bool,
  includeWinnersLink: PropTypes.bool.isRequired,
  toggleContactModalState: PropTypes.func.isRequired,
  displayContactModal: PropTypes.bool.isRequired,
};

Footer.defaultProps = {
  maintenance: false,
};

export default Footer;
