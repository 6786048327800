// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const NavItem = ({ name, path }) => (
  <li className="main-nav__list-item">
    <a className="main-nav__link" href={path ? `/${path}` : '#'}>
      <span className="main-nav__link-marker theme-background-secondary" />
      {name}
    </a>
  </li>
);

NavItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default NavItem;
