// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Counter from 'marketing/components/Counter';

const AddOn5050 = ({
  mainHeading,
  pageBannerText,
  imageSrc,
  pageBackgroundImage,
  winner,
  total,
}) => (
  <div
    className="addon-block addon-block--5050 l-margin-bottom theme-background-primary"
    style={
      pageBackgroundImage && { backgroundImage: `url(${pageBackgroundImage})` }
    }
  >
    {imageSrc && (
      <div className="addon-block__img-container">
        <img
          className="addon-block__img"
          src={imageSrc}
          alt="50 50 add on prize logo"
        />
      </div>
    )}

    <div className="addon-block__copy-wrapper">
      {mainHeading && (
        <h2 className="addon-block__heading addon-block__heading--main h2 alt">
          {mainHeading}
        </h2>
      )}

      <div className="addon-block__counter-container">
        <Counter total={total} />
      </div>

      {pageBannerText && (
        <p
          className={`addon-block__heading addon-block__heading${
            winner ? '--sub-winner h4' : '--sub h3'
          }`}
        >
          {pageBannerText}
        </p>
      )}
    </div>
  </div>
);

AddOn5050.propTypes = {
  mainHeading: PropTypes.string.isRequired,
  pageBannerText: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  imageSrc: PropTypes.string.isRequired,
  pageBackgroundImage: PropTypes.string.isRequired,
  winner: PropTypes.string,
};

AddOn5050.defaultProps = {
  winner: '',
};

export default AddOn5050;
