// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const AddOn100Days = ({
  mainHeading,
  pageBannerText,
  imageSrc,
  pageBackgroundImage,
}) => (
  <div
    className="addon-block addon-block--100days l-margin-bottom theme-background-primary"
    style={
      pageBackgroundImage && { backgroundImage: `url(${pageBackgroundImage})` }
    }
  >
    {imageSrc && (
      <div className="addon-block__img-container">
        <img
          className="addon-block__img"
          src={imageSrc}
          alt="Cash Calendar prize logo"
        />
      </div>
    )}

    <div className="addon-block__copy-wrapper">
      {mainHeading && (
        <h2 className="addon-block__heading addon-block__heading--main h2 alt">
          {mainHeading}
        </h2>
      )}

      {pageBannerText && (
        <p className="addon-block__heading addon-block__heading--sub h3">
          {pageBannerText}
        </p>
      )}
    </div>
  </div>
);

AddOn100Days.propTypes = {
  mainHeading: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  pageBackgroundImage: PropTypes.string.isRequired,
  pageBannerText: PropTypes.string,
};

AddOn100Days.defaultProps = {
  pageBannerText: '',
};

export default AddOn100Days;
