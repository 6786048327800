// Libraries
import React from 'react';

// Data
import data from 'data/data.json';

// Styles
import { supporters, container, logo } from './styles.module.css';

const SupportersSection = () => {
  const {
    globalModule: { supportingLogos, supportingHeading } = {},
  } = data.raffle;

  return (
    <div className={supporters}>
      {supportingLogos && supportingLogos.length > 0 && (
        <div className={container}>
          {supportingLogos.map((imageSrc, i) => (
            <img
              className={logo}
              src={imageSrc}
              key={parseInt(i.toString(), 10)} // TODO decide on a better way of this.
              alt="logo"
            />
          ))}
        </div>
      )}

      {supportingHeading && (
        <h5 className="theme-color-primary">{supportingHeading}</h5>
      )}
    </div>
  );
};

export default SupportersSection;
