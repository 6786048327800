// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Swiper from 'react-id-swiper';
import Hero from 'marketing/components/Hero';

const params = {
  containerClass:
    'swiper-container swiper--grand-prize theme-background-primary',
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: (i, className) => `<span class="theme-background-secondary ${className}"></span>`,
  },
  navigation: {
    nextEl: '.js-swiper-next.swiper-button-next.theme-background-secondary',
    prevEl: '.js-swiper-prev.swiper-button-prev.theme-background-secondary',
    clickable: true,
  },
  simulateTouch: false,
  renderPrevButton: () => (
    <div className="js-swiper-prev swiper-button-prev theme-background-secondary">
      <i className="material-icons">keyboard_arrow_left</i>
    </div>
  ),
  renderNextButton: () => (
    <div className="js-swiper-next swiper-button-next theme-background-secondary">
      <i className="material-icons">keyboard_arrow_right</i>
    </div>
  ),
};

const GrandPrizeSwiper = ({ slides, programLogo }) => (
  <Swiper {...params}>
    {slides
      && slides.map(({ subheading, heading, image }, i) => (
        <div className="swiper-slide" key={i}>
          <Hero
            subHeading={subheading}
            mainHeading={heading}
            backgroundUrl={image}
            logoUrl={programLogo}
            orderTickets
          />
        </div>
      ))}
  </Swiper>
);

GrandPrizeSwiper.propTypes = {
  programLogo: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      image: PropTypes.string,
      subheading: PropTypes.string,
    }),
  ).isRequired,
};

export default GrandPrizeSwiper;
